// dropdown_controller.js
import { Controller } from "@hotwired/stimulus"
import {enter, leave, toggle} from 'el-transition'

export default class extends Controller {

  static targets = [ "menu", "button" ]
  static values = { open: Boolean }

  connect() {
    console.log("Hello, Stimulus!")
  }

  toggle() {
    this.openValue = !this.openValue
  }

  openValueChanged() {
    if (this.openValue) {
      this._show()
    } else {
      this._hide()
    }
  }

  _show() {
    enter(this.menuTarget)
  }

  _hide() {
    leave(this.menuTarget)
  }

  show(event) {
    if (this.element.contains(event.target) === true && this.openValue === false) {
      this.openValue = true
    }
  }

  hide(event) {
    if (this.element.contains(event.target) === false && this.openValue) {
      console.log("hidden")
      this.openValue = false
    }
  }

  changeLabel(event) {
    this.buttonTarget.innerHTML = event.currentTarget.dataset.selection
  }
}